import React from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import bagSvg from '~/assets/img/svg/bag.svg'
import rightArrow from '~/assets/img/svg/right-arrow.svg'
import { useApi } from '~/api'
import cn from 'classnames'

import * as st from '~/assets/styl/ShoppingPackages.module.styl'
import { LazyImage } from 'react-lazy-images'
import SoldOutTag from './SoldOutTag'

export default function ShoppingPackages() {
  const shoppingCard = useApi([], 'shoppings')

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={st.shoppingPacksContainer}>
      <div className={st.shoppingHeader}>
        <img src={bagSvg} alt="Ícone de uma mala de viagem" />
        <h2>Viagens de compras</h2>
        <p>Aproveite nossas rotas para sua viagem de compras</p>
      </div>
      <ul className={cn(st.shoppingCardsWrapper, 'hide-on-mobile')}>
        {shoppingCard.slice(0, 6).map((item, key) => (
          <li key={key} className={st.shoppingCard}>
            <Link to={'viagens/' + item.slug + '/'} title={item.name}>
              <div
                className={cn(st.shoppingCardHeader, {
                  [st.backgroundMissingImage]: !item.image,
                })}
              >
                <LazyImage
                  src={item.image}
                  alt={item.name}
                  placeholder={({ imageProps, ref }) => (
                    <img ref={ref} src={item.image} alt={item.name} />
                  )}
                  actual={({ imageProps }) => (
                    <img {...imageProps} style={{ width: '100%' }} />
                  )}
                />
                {item.payment && (
                  <div className={st.installmentBar}>
                    <span>{item.payment}</span>
                  </div>
                )}
              </div>
              <div className={st.shoppingCardBody}>
                <div className={st.destination}>
                  <span>
                    <b>{item.name}</b> - {item.region}
                  </span>
                  {item.unavailable && <SoldOutTag />}
                </div>
                <div className={st.descriptions}>
                  <div className={st.departing}>
                    <img src={rightArrow} />
                    <span>{item.exit_at}</span>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <ul className={cn(st.shoppingMobileCardsWrapper, 'hide-on-desktop')}>
        {Boolean(shoppingCard.length) && (
          <Slider {...settings}>
            {shoppingCard.slice(0, 6).map((item, key) => (
              <li key={key} className={st.shoppingCard}>
                <Link to={'viagens/' + item.slug + '/'} title={item.title}>
                  <div
                    className={cn(st.shoppingCardHeader, {
                      [st.backgroundMissingImage]: !item.image,
                    })}
                  >
                    {item.image && (
                      <LazyImage
                        src={item.image}
                        alt={item.name}
                        placeholder={({ imageProps, ref }) => (
                          <img ref={ref} src={item.image} alt={item.name} />
                        )}
                        actual={({ imageProps }) => (
                          <img {...imageProps} style={{ width: '100%' }} />
                        )}
                      />
                    )}
                    {item.payment && (
                      <div className={st.installmentBar}>
                        <span>{item.payment}</span>
                      </div>
                    )}
                  </div>
                  <div className={st.shoppingCardBody}>
                    <div className={st.destination}>
                      <span>
                        <b>{item.name}</b> - {item.region}
                      </span>
                      {item.unavailable && <SoldOutTag />}
                    </div>
                    <div className={st.descriptions}>
                      <div className={st.departing}>
                        <img src={rightArrow} />
                        <span>Saída: {item.exit_at}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </Slider>
        )}
      </ul>
      <div className={st.otherPackages}>
        <Link to="/viagem-de-compras" title="Bauer Turismos - Outras Viagens">
          Veja outros pacotes
        </Link>
      </div>
    </div>
  )
}
