import React, { useEffect, useState } from 'react'

import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import { useApi } from '~/api'
import Slider from 'react-slick'

import { Oval } from 'react-loader-spinner'

import * as st from '~/assets/styl/Header.module.styl'

import headerBg from '~/assets/img/mocks/bg-header.jpg'
import searchIcon from '~/assets/img/svg/search.svg'

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export default function Header() {
  const [currentyTab, setCurrentyTab] = useState('tourism')
  const [searchBoxActive, setSearchBoxActive] = useState(false)
  const [searchBoxValue, setSearchBoxValue] = useState('')

  const travelCard = useApi([], 'packages')
  const shoppingCard = useApi([], 'shoppings')

  const showcases = useApi(
    useStaticQuery(graphql`
      query {
        allShowcases {
          nodes {
            id
            image
            mobile
            name
          }
        }
      }
    `).allShowcases.nodes,
    'showcases'
  )

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 10000,
    cssEase: 'linear',
  }

  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setisLoading(false), 2200)
  })

  function handleTabShow(event) {
    if (event.target.value === 'tourism') {
      setCurrentyTab('tourism')
    } else {
      setCurrentyTab('shopping')
    }
  }

  function toggleSearchBox(e) {
    e.preventDefault()
    setSearchBoxActive(true)
  }

  function handleCloseSearchBox(e) {
    e.preventDefault()
    setTimeout(() => {
      setSearchBoxActive(false)
    }, 200)
  }

  function handleSearchBoxChange(e) {
    setSearchBoxValue(e.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()

    navigate(
      `/resultados-busca/${searchBoxValue
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')}`
    )
    setSearchBoxValue('')
  }

  const travelSearchResultGrouped = groupBy(travelCard, 'name')

  const travelSearchResult = Object.keys(travelSearchResultGrouped)
    .map((k) => {
      const t = travelSearchResultGrouped[k]
      const temp = t[0]
      if (t.length > 1) {
        temp.url = `/pacotes-turisticos/${temp.name}`
      } else {
        temp.url = `/pacotes/${temp.slug}`
      }
      return temp
    })
    .filter(
      (t) =>
        t.name.toUpperCase().includes(searchBoxValue.toUpperCase()) ||
        searchBoxValue === ''
    )

  const shoppingSearchResultGrouped = groupBy(shoppingCard, 'name')

  const shoppingSearchResult = Object.keys(shoppingSearchResultGrouped)
    .map((k) => {
      const t = shoppingSearchResultGrouped[k]
      const temp = t[0]
      if (t.length > 1) {
        temp.url = `/viagem-de-compra/${temp.name}`
      } else {
        temp.url = `/viagens/${temp.slug}`
      }
      return temp
    })
    .filter(
      (t) =>
        t.name.toUpperCase().includes(searchBoxValue.toUpperCase()) ||
        searchBoxValue === ''
    )

  return (
    <div className={cn(st.headerContainer, 'header-wrapper')}>
      <div className={st.showcaseContainer}>
        <Slider {...settings}>
          {showcases.map((showcase) => (
            <div key={showcase.id}>
              <div className={st.overlay}></div>
              <img src={showcase.image} alt={showcase.name} />
            </div>
          ))}
        </Slider>
      </div>
      <div className={st.searchContainer}>
        <h1>Pensou em viajar? Programe sua viagem com a Bauer.</h1>
        <div className={st.searchWrapper}>
          <div className={st.buttonWrapper}>
            <button
              className={currentyTab === 'tourism' && st.active}
              value="tourism"
              type="button"
              onClick={handleTabShow}
            >
              Turismo
            </button>
            <button
              className={currentyTab === 'shopping' && st.active}
              value="shopping"
              type="button"
              onClick={handleTabShow}
            >
              Compras
            </button>
            <Link to="/servicos/fretamento-e-viagens">Conheça nossa frota</Link>
          </div>
          <div className={st.inputWrapper}>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={searchBoxValue}
                onClick={toggleSearchBox}
                onBlur={handleCloseSearchBox}
                onChange={handleSearchBoxChange}
                placeholder="Escolha seu destino"
              />
              <button type="submit">
                <img
                  src={searchIcon}
                  alt="Ícone de uma lupa para pesquisa"
                  width={20}
                  height={20}
                />
              </button>
            </form>
          </div>
          <div className={cn(st.searchBox, { [st.active]: searchBoxActive })}>
            <ul>
              {(currentyTab === 'tourism' && (
                <>
                  {isLoading ? (
                    <Oval
                      height="35"
                      width="35"
                      color="#FCAA00"
                      secondaryColor="#CB0103"
                      ariaLabel="loading"
                      wrapperStyle={{ justifyContent: 'center' }}
                    />
                  ) : travelSearchResult.length === 0 ? (
                    <li>
                      <span>Nenhum resultado encontrado</span>
                    </li>
                  ) : (
                    travelSearchResult.slice(0, 9).map((t, index) => (
                      <>
                        <li key={index}>
                          <Link to={t.url}>
                            <span>
                              <b>{t.name}</b>{' '}
                              <span className="hide-on-mobile">
                                - {t.region}
                              </span>
                            </span>
                            <span className="icon-right-control"></span>
                          </Link>
                        </li>
                      </>
                    ))
                  )}
                </>
              )) || (
                <>
                  {isLoading ? (
                    <Oval
                      height="35"
                      width="35"
                      color="#FCAA00"
                      secondaryColor="#CB0103"
                      ariaLabel="loading"
                      wrapperStyle={{ justifyContent: 'center' }}
                    />
                  ) : shoppingSearchResult.length === 0 ? (
                    <li>
                      <span>Nenhum resultado encontrado</span>
                    </li>
                  ) : (
                    shoppingSearchResult.slice(0, 9).map((s, index) => (
                      <li key={index}>
                        <Link to={`/viagens/${s.slug}`}>
                          <span>
                            <b>{s.name}</b> - {s.region}
                          </span>
                          <span className="icon-right-control"></span>
                        </Link>
                      </li>
                    ))
                  )}
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
