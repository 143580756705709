import React from 'react'
import { Head } from '~/components'
import Header from '~/components/Header'
import TravelPackages from '~/components/TravelPackages'
import ImageAndButton from '~/components/ImageAndButton'
import ShoppingPackages from '~/components/ShoppingPackages'
import VipLounge from '~/components/VipLounge'
import Services from '~/components/Services'
import Testimonials from '~/components/Testimonials'
import Companies from '~/components/Companies'
import Newsletter from '~/components/Newsletter'
import Footer from '~/components/Footer'
// import * as st from '~/assets/styl/Modal.module.styl'

import shippingBg from '~/assets/img/bus.jpg'
import busSvg from '~/assets/img/svg/bus.svg'
// import close from '~/assets/img/svg/close-icon.svg'
import storeBg from '~/assets/img/loja.jpg'
import storeSvg from '~/assets/img/svg/store.svg'
// import Modal from 'react-modal'
// import popup from '~/assets/img/banner-modal-blackfriday.jpg'
import { Link } from 'gatsby'

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '998',
  },
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: 'auto',
    height: 'auto',
    width: '600px',
    maxWidth: '100%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: 'transparent',
    border: 'none',
    zIndex: '999',
  },
}

const Home = () => {
  // const [modalIsOpen, setIsOpen] = React.useState(true)

  return (
    <>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={() => setIsOpen(false)}>
          <img src={close} className={st.closeIcon} />
        </button>

        <div className={st.modalContainer}>
          <Link to={'/pacotes-turisticos'}>
            <img src={popup} />
          </Link>
        </div>
      </Modal> */}
      <Head />
      <Header />
      <TravelPackages />
      <ImageAndButton
        image={shippingBg}
        icon={busSvg}
        title="Conheça as opções de fretamento"
        anchor="/servicos/fretamento-e-viagens"
      />
      <ShoppingPackages />
      <VipLounge />
      {/* <ImageAndButton
        image={storeBg}
        icon={storeSvg}
        title="Conheça a Loja Bauer"
        description="A Loja Bauer oferece as melhores opções para quem precisa comprar pacotes de viagens, passagens aéreas e cruzeiros e os melhores serviços de transporte para quem necessita enviar mercadorias de forma rápida e com a garantia de que chegará ao destino em segurança."
      /> */}
      <Services serviceTitle="Serviços que facilitam a sua viagem" />
      <Testimonials />
      {/* <Companies /> */}
      <Newsletter />
      <Footer />
    </>
  )
}

export default Home
