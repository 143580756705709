import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Slider from 'react-slick'
import { useApi } from '~/api'
import { Link } from 'gatsby'
import caseSvg from '~/assets/img/svg/case.svg'
import rightArrow from '~/assets/img/svg/right-arrow.svg'
import leftArrow from '~/assets/img/svg/left-arrow.svg'
import * as st from '~/assets/styl/TravelPackages.module.styl'
import { LazyImage } from 'react-lazy-images'
import SoldOutTag from './SoldOutTag'

export default function TravelPackages() {
  const travelCard = useApi([], 'packages')

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  const idNotBlackFriday = [70, 69, 80, 81, 79, 68, 109]

  return (
    <div className={st.travelPacksContainer}>
      <div className={st.travelHeader}>
        <img src={caseSvg} alt="Ícone de uma mala de viagem" />
        <h2>Pacotes Turísticos</h2>
        <p>Os melhores destinos para sua viagem você encontra aqui.</p>
      </div>
      <ul className={cn(st.travelCardsWrapper, 'hide-on-mobile')}>
        {travelCard.slice(0, 6).map((item, key) => (
          <li key={key} className={st.travelCard}>
            <Link to={'pacotes/' + item.slug + '/'} title={item.title}>
              <div
                className={cn(st.travelCardHeader, {
                  [st.backgroundMissingImage]: !item.image,
                })}
              >
                <div className={st.containerImages}>
                  {item.image && (
                    <LazyImage
                      src={item.image}
                      alt={item.name}
                      placeholder={({ imageProps, ref }) => (
                        <img ref={ref} src={item.image} alt={item.name} />
                      )}
                      actual={({ imageProps }) => (
                        <img {...imageProps} style={{ width: '100%' }} />
                      )}
                    />
                  )}
                  {/* {!idNotBlackFriday.includes(item.id) && (
                    <span className={st.tags}>Black Friday</span>
                  )} */}
                </div>
                {item.payment && (
                  <div className={st.installmentBar}>
                    <span>{item.payment}</span>
                  </div>
                )}
              </div>
              <div className={st.travelCardBody}>
                <div className={st.destination}>
                  <span>
                    <b>{item.name}</b> - {item.region}
                  </span>
                  {item.unavailable && <SoldOutTag />}
                </div>
                <div className={st.descriptions}>
                  <div className={st.departing}>
                    <img src={rightArrow} />
                    <span>Saída: {item.exit_at}</span>
                  </div>
                  <div className={st.returning}>
                    <img src={leftArrow} />
                    <span>Retorno: {item.return_at}</span>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <ul className={cn(st.travelMobileCardsWrapper, 'hide-on-desktop')}>
        {Boolean(travelCard.length) && (
          <Slider {...settings}>
            {travelCard.slice(0, 6).map((item, key) => (
              <li key={key} className={st.travelCard}>
                <Link to={'pacotes/' + item.slug + '/'} title={item.title}>
                  <div
                    className={cn(st.travelCardHeader, {
                      [st.backgroundMissingImage]: !item.image,
                    })}
                  >
                    <div className={st.containerImages}>
                      {item.image && (
                        <LazyImage
                          src={item.image}
                          alt={item.name}
                          placeholder={({ imageProps, ref }) => (
                            <img ref={ref} src={item.image} alt={item.name} />
                          )}
                          actual={({ imageProps }) => (
                            <img {...imageProps} style={{ width: '100%' }} />
                          )}
                        />
                      )}
                      {/* <span className={st.tags}>Black Friday</span> */}
                    </div>
                    {item.payment && (
                      <div className={st.installmentBar}>
                        <span>{item.payment}</span>
                      </div>
                    )}
                  </div>
                  <div className={st.travelCardBody}>
                    <div className={st.destination}>
                      <span>
                        <b>{item.name}</b> - {item.region}
                      </span>
                      {item.unavailable && <SoldOutTag />}
                    </div>
                    <div className={st.descriptions}>
                      <div className={st.departing}>
                        <img src={rightArrow} />
                        <span>Saída: {item.exit_at}</span>
                      </div>
                      <div className={st.returning}>
                        <img src={leftArrow} />
                        <span>Retorno: {item.return_at}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </Slider>
        )}
      </ul>
      <div className={st.otherPackages}>
        <Link to="/pacotes-turisticos" title="Bauer Turismos - Outros Pacotes">
          Veja outros pacotes
        </Link>
      </div>
    </div>
  )
}

TravelPackages.propTypes = {
  homeMarginTop: PropTypes.string,
}
