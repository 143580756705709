// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "ShoppingPackages-module--background-missing-image--MPJLl";
export var departing = "ShoppingPackages-module--departing--dBY2Y";
export var descriptions = "ShoppingPackages-module--descriptions--WLlTn";
export var destination = "ShoppingPackages-module--destination--0vot1";
export var installmentBar = "ShoppingPackages-module--installment-bar--XjeuZ";
export var otherPackages = "ShoppingPackages-module--other-packages--EkkpD";
export var returning = "ShoppingPackages-module--returning--w8vJZ";
export var shoppingCard = "ShoppingPackages-module--shopping-card--pBAdw";
export var shoppingCardBody = "ShoppingPackages-module--shopping-card-body--P+CkZ";
export var shoppingCardHeader = "ShoppingPackages-module--shopping-card-header--vHLOV";
export var shoppingCardhover = "ShoppingPackages-module--shopping-cardhover--hM7N8";
export var shoppingCardsWrapper = "ShoppingPackages-module--shopping-cards-wrapper--MxH+y";
export var shoppingHeader = "ShoppingPackages-module--shopping-header--1o321";
export var shoppingMobileCardsWrapper = "ShoppingPackages-module--shopping-mobile-cards-wrapper--Jgae2";
export var shoppingPacksContainer = "ShoppingPackages-module--shopping-packs-container--EMJnV";