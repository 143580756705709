import { Link } from 'gatsby'
import { navigate } from 'gatsby'
import React, { useState, useRef } from 'react'
import * as st from '~/assets/styl/Newsletter.module.styl'
import ReCAPTCHA from 'react-google-recaptcha'

import api from '~/api'

type RecaptchaProps = {
  className?: string
  inputRef: React.MutableRefObject<ReCAPTCHA>
}

export const Recaptcha = ({ inputRef: ref, className }: RecaptchaProps) => (
  <ReCAPTCHA
    ref={ref}
    sitekey="6LfQlx8gAAAAAHCnHDrGOmfl3iktSLsBkjei9Lbx"
    size="invisible"
    className={className}
  />
)

export default function Newsletter() {
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [data, setData] = useState({
    privacy: false,
    email: '',
    recaptcha: '',
  })

  const success = () => {
    navigate('/obrigado/')
  }

  function handleSubmit(recaptcha: string) {
    api(
      'post',
      'leads/subscriber',
      { ...data, recaptcha },
      { headers: { 'Content-Type': 'text/plain' } }
    ).then((data) => {
      if (data.code === 0) {
        success()
      } else {
        recaptchaRef.current.reset()
      }
    })
  }

  return (
    <section className={st.newsletterContainer}>
      <h2>
        Cadastre-se e receba em seu e-mail informações dos nossos pacotes e
        serviços.
      </h2>
      <form
        className={st.newsletterForm}
        onSubmit={(e) => {
          e.preventDefault()
          recaptchaRef.current
            .executeAsync()
            .then((value) => handleSubmit(value))
        }}
      >
        <div className={st.inputWrapper}>
          <label>
            <input
              type="email"
              placeholder="Qual seu e-mail?"
              onChange={(e) => setData({ ...data, email: e.target.value })}
              required
            />
          </label>
          <Recaptcha inputRef={recaptchaRef} />
          <button type="submit">
            <span className="icon-right-control"></span>
          </button>
        </div>
        <div className={st.privacy}>
          <label>
            <input
              id="use_terms"
              type="checkbox"
              name="privacy"
              onChange={(e) => setData({ ...data, privacy: e.target.checked })}
              required
            />
            <small>
              Declaro que li e aceito a{' '}
              <Link to="/politica-de-privacidade">Política de privacidade</Link>
            </small>
          </label>
        </div>
      </form>
    </section>
  )
}
