import React from 'react'
import PropTypes from 'prop-types'
import * as st from '~/assets/styl/ImageAndButton.module.styl'
import { Link } from 'gatsby'

export default function ImageAndButton(props) {
  const { image, icon, title, anchor, description } = props
  return (
    <div className={st.shippingAndTravel}>
      <div className={st.shippingAndTravelContainer}>
        <div
          className={st.shippingAndTravelBus}
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></div>
        <section>
          <div className={st.container}>
            <img src={icon} />
            <h2>{title}</h2>
            <div className={st.buttonsAnchor}>
              {anchor && <Link to={anchor}>Saiba mais</Link>}
              {anchor && <Link to={anchor}>Conheça nossa frota</Link>}
            </div>
            {description && <p>{description}</p>}
          </div>
        </section>
      </div>
    </div>
  )
}

ImageAndButton.propTypes = {
  image: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  anchor: PropTypes.string,
  description: PropTypes.string,
}
