import React, { useEffect, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useApi } from '~/api'
import Slider from 'react-slick'

import * as st from '~/assets/styl/Testimonials.module.styl'
import '../assets/styl/slick.css'

export default function Testimonials() {
  const testimonialsCards = useApi(
    useStaticQuery(graphql`
      query {
        allTestimonials {
          nodes {
            name
            place
            text
            image
          }
        }
      }
    `).allTestimonials.nodes,
    'testimonials'
  )

  const slider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const lastSlide = 1

  function handleSlideChange(index) {
    setCurrentSlide(index)
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  if (testimonialsCards.length === 0) {
    return null // Não renderiza nada
  }

  return (
    <div className={st.testimonialsContainer}>
      {testimonialsCards.length > 0 && (
        <>
          <div className={st.descriptionWrapper}>
            <h2>A satisfação dos nossos clientes é a nossa satisfação</h2>
            <p>
              Confia os depoimentos dos clientes que já viajaram com a Bauer
              Turismo
            </p>
          </div>
          <div className={st.sliderWrapper}>
            {Boolean(testimonialsCards.length) && (
              <Slider {...settings} ref={slider}>
                {testimonialsCards.map((card, index) => (
                  <div key={index} className={st.testimonialCard}>
                    <span className="icon-heart"></span>
                    <div className={st.infosWrapper}>
                      {card.image && <img src={card.image} alt={card.title} />}
                      <section>
                        <h3>
                          <b>{card.name}</b>
                        </h3>
                        <p>{card.place}</p>
                      </section>
                    </div>
                    <p className={st.description}>{card.text}</p>
                  </div>
                ))}
              </Slider>
            )}
            {testimonialsCards.length > 2 && (
              <div className={st.controls}>
                <button
                  onClick={() => {
                    slider?.current?.slickPrev()
                    handleSlideChange(currentSlide - 1)
                  }}
                  className={currentSlide === 0 && st.disabled}
                  disabled={currentSlide === 0}
                >
                  <span className="icon-left-control"></span>
                </button>
                <button
                  onClick={() => {
                    slider?.current?.slickNext()
                    handleSlideChange(currentSlide + 1)
                  }}
                  className={currentSlide === lastSlide && st.disabled}
                  disabled={currentSlide === lastSlide}
                >
                  <span className="icon-right-control"></span>
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
