// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "TravelPackages-module--background-missing-image--mkQzF";
export var containerImages = "TravelPackages-module--container-images--Va5JX";
export var departing = "TravelPackages-module--departing--pENkM";
export var descriptions = "TravelPackages-module--descriptions--aJX7V";
export var destination = "TravelPackages-module--destination--Pekpr";
export var installmentBar = "TravelPackages-module--installment-bar--pqsR-";
export var otherPackages = "TravelPackages-module--other-packages--Nrdpd";
export var returning = "TravelPackages-module--returning--cu9Ol";
export var tags = "TravelPackages-module--tags--38cHJ";
export var travelCard = "TravelPackages-module--travel-card--yJ5y0";
export var travelCardBody = "TravelPackages-module--travel-card-body--8BbUQ";
export var travelCardHeader = "TravelPackages-module--travel-card-header--Wb+V7";
export var travelCardsWrapper = "TravelPackages-module--travel-cards-wrapper--yXg-x";
export var travelHeader = "TravelPackages-module--travel-header--F6ZeY";
export var travelMobileCardsWrapper = "TravelPackages-module--travel-mobile-cards-wrapper--carEC";
export var travelPacksContainer = "TravelPackages-module--travel-packs-container--vxjfX";